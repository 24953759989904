import {useEffect} from "react";
import {navigate} from "gatsby";

const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
        return "en";
    }

    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";

    console.log(lang);

    switch (lang) {
        case "de":
            return "de";
        default:
            return "en";
    }
}

const IndexPage = () => {
    useEffect(() => {
        const urlLang = getRedirectLanguage();

        navigate(`/${urlLang}/`, {replace: true});
    }, []);

    return null;
}

export default IndexPage
